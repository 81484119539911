<template>
  <div>

    <!-- Main Row -->
    <div class="row">

      <!-- Form -->
      <div class="col-md-12">
        <Card :title="'Detail Perjanjian'">
          <template v-slot:body>
            <div class="row align-items-center">

              <!-- Profile Image -->
              <div class="col-md-4">
                <div class="d-flex justify-content-center align-items-center image-container">
                  <div style='height: 350px; width: 350px; position: relative'>
                    <img
                      class="image"
                      src="/images/appointment-splash.svg"
                      @load="onImageLoad()"
                    >
                  </div>
                </div>
              </div>

              <!-- Table Data -->
              <div
                class="col"
                v-if="imgLoaded"
              >
                <table class="table mb-3">
                  <tr>
                    <td style="width: 25%"><strong>Dokter</strong></td>
                    <td>{{ data.doctor_name }}</td>
                  </tr>
                  <tr>
                    <td><strong>Pasien</strong></td>
                    <td>{{ data.patient_name }}</td>
                  </tr>
                  <tr>
                    <td><strong>Tanggal</strong></td>
                    <td>{{ new Date(data.date).toLocaleString('id-ID', { weekday: 'long', day: 'numeric', month: '2-digit', year: 'numeric' }).replaceAll('/', '-') }}</td>
                  </tr>
                  <tr>
                    <td><strong>Waktu</strong></td>
                    <td>{{ data.time }}</td>
                  </tr>
                  <tr>
                    <td><strong>Ruang</strong></td>
                    <td>{{ data.room_name }}</td>
                  </tr>
                  <tr>
                    <td><strong>Tautan Meeting</strong></td>
                    <td>{{ data.live_meeting_link }}</td>
                  </tr>
                  <tr>
                    <td><strong>Status</strong></td>
                    <td>{{ data.appointment_status_name }}</td>
                  </tr>
                  <tr>
                    <td><strong>Catatan</strong></td>
                    <td>{{ data.notes }}</td>
                  </tr>
                </table>

                <b-button
                  variant="primary"
                  class="mx-1"
                  @click="btnPrintStickerOnClick(data)"
                ><i class="fas fa-print"></i> Cetak Stiker</b-button>
                <button
                  @click="$router.push({path: '/appointment/edit/' + data.id})"
                  class="btn mx-1 btn-success"
                  v-if="manipulateBtn == true"
                ><i class="far fa-fw fa-edit"></i> Edit</button>
                <button
                  @click="btnDeleteOnClick"
                  class="btn mx-1 btn-danger"
                  v-if="manipulateBtn == true"
                ><i class="far fa-fw fa-trash-alt"></i> Hapus</button>
              </div>

            </div>
          </template>
        </Card>
      </div>

      <!-- print layout -->
      <ModalFormSticker
        :data="print"
        :count="printCount"
      />

    </div>

  </div>
</template>

<script>

import Card from "@/view/content/Card.vue"
import ModalFormSticker from '@/component/appointments/ModalFormStickerPrint.vue'
import module from '@/core/modules/CrudModule.js'
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module"

export default {

  data() {
    return {
      // Data
      data: {},
      // Other
      imgLoaded: false,
      // sticker print data
      print: {
        patient_number: '',
        patient_name: '',
        patient_gender: '',
        patient_birth_date: '',
        appointment_date: ''
      },
      printCount: 2,
      // access management
      manipulateBtn: false,
    }
  },

  components: {
    Card,
    ModalFormSticker
  },

  methods: {

    onImageLoad() {
      this.imgLoaded = true
    },

    btnPrintStickerOnClick(data) {      
      this.$bvModal.show('modal-form-sticker-print')
      this.print.patient_number = data.patient_number
      this.print.patient_name = data.display_patient_name
      this.print.patient_gender = data.patient_gender
      this.print.patient_birth_date = data.patient_birth_date
      this.print.appointment_date = data.date
    },

    async btnDeleteOnClick() {
      let result = await module.delete('appointments/' + this.$route.params.id)
      // If Deleted
      if (result) {
        // Redirect To List
        this.$router.push('/appointment/list/0')
      }
    },

    async get() {
      this.data = await module.get('appointments/' + this.$route.params.id)
      // If Data Not Found
      if (this.data == null) {
        // Redirect To List
        this.$router.push('/appointment/list/0')
      }
    },

    // access management
    async setActiveMenu() {
      let access_right_user = window.localStorage.getItem("access_right_display")
      let access_right = JSON.parse(access_right_user)
      let a
      for (a = 0; a < access_right.length; a++) {
        console.log("looping")
        if (access_right[a] == "5002") {
          this.manipulateBtn = true
        }
      }
    },

  },

  mounted() {
    // BreadCrumb
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Perjanjian", route: "" },
      { title: "Daftar Perjanjian", route: "/appointment/list/0" },
      { title: "Detail" },
    ])
    // Get Data
    this.get()
    // access management
    this.setActiveMenu()
  },
}

</script>